import {Vue} from "vue-property-decorator";
import PotentialClient from "@/models/PotentialClient";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import JsonTool from "@/services/tool/JsonTool";
import axios from "axios";
import Response from "@/models/responses/Response";

export default class PotentialClientService {

    static async postPotentialClient(component: Vue, potentialClient: PotentialClient) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/potential-clients", potentialClient, {
                    headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("El cliente potencial ha sido creado.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al crear el cliente potencial.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async patchPotentialClient(component: Vue, potentialClient: PotentialClient, id: number) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/@me/potential-clients/" + id, potentialClient, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("El cliente potencial ha sido actualizado con exito.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al actualizar el cliente potencial.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getPotentialClient(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/potential-clients/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.potentialClient = JsonTool.jsonConvert.deserializeObject(response.data, PotentialClient)
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo obtener el cliente potencial")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getPotentialClients(component: Vue, potentialClients: PotentialClient[], page: number, size: number, search: string, all: boolean = false, active: boolean = true) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/potential-clients", {
                params: { page: page, size, search, all, active },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            potentialClients.splice(0, potentialClients.length)
            JsonTool.jsonConvert.deserializeArray(response.data, PotentialClient).forEach(p => potentialClients.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
        } catch(e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getPotentialClients2(
        page: number, size: number, search: Nullable<string>, all: boolean = false, active: boolean = true
    ): Promise<Response<PotentialClient[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/potential-clients", {
                params: { page, size, search, all, active },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const potentialClients: PotentialClient[] = JsonTool.jsonConvert.deserializeArray(response.data, PotentialClient)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: potentialClients, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async getMyPotentialClients(component: Vue, potentialClients: PotentialClient[], page: number, size: number, search: string, active: boolean = true, userId: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/users/" + userId + "/potential-clients", {
                params: { page: page, size, search, active: active },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            potentialClients.splice(0, potentialClients.length)
            JsonTool.jsonConvert.deserializeArray(response.data, PotentialClient).forEach(p => potentialClients.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
        } catch(e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async deletePotentialClient(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.delete(ConstantTool.BASE_URL + "/api/@me/potential-clients/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            getModule(SnackbarModule).makeToast("El cliente potencial ha sido eliminado con exito.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han eliminar el cliente potencial")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async activatePotentialClient(component: Vue, id: number, enable: boolean) {
        let formData = new FormData()
        formData.set("active", `${enable}`)

        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/@me/potential-clients/"+ id +"/activate", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se han cambiar el estado del cliente")
        }
    }

    static async getMyPotentialClientCSV(
        component: Vue, page: number, size: number, search: string, all: boolean = false, active: boolean = true
    ) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/potential-clients/csv", {
                params: { page, size, search, all, active },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const blob = new Blob([ response.data ], { "type" : "text/csv" })
            let link = document.createElement('a')
            // @ts-ignore
            link.href = window.URL.createObjectURL(blob)
            link.download = 'potential-client.csv'
            // @ts-ignore
            link.click()
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes potenciales.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

}