    import Budget, {BudgetType} from "@/models/Budget";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import Order from "@/models/Order";
import SnackbarModule from "@/store/SnackbarModule";
    import {Vue} from "vue-property-decorator";
    import User from "@/models/User";

export default class BudgetService {

    static async getBudget(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/budgets/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.budget = JsonTool.jsonConvert.deserializeObject(response.data, Budget)
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al buscar el presupuesto.")
        } finally {
            // @ts-ignore
            component.loading = true
        }
    }

    static async getBudgets(
        component: Vue, budgets: Budget[], page: number, size: number, search: string | null,
        potentialClientId: number | null, companyId: number | null, start: string | null, end: string | null, userId: string | null
    ) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/budgets", {
                params: { page, size, search, potentialClientId, companyId, start, end, userId },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            budgets.splice(0, budgets.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Budget).forEach(p => budgets.push(p))
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los presupuestos.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getMyBudgets(
        component: Vue, budgets: Budget[], page: number, size: number, userId: number | null, search: string | null,
        budgetType: BudgetType | null, potentialClientId: number | null, start: string | null, end: string | null,
    ) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/budgets", {
                params: { page, size, userId, search, budgetType, potentialClientId, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            budgets.splice(0, budgets.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Budget).forEach(p => budgets.push(p))
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al buscar los presupuestos.")
        } finally {
            // @ts-ignore
            component.loading = true
        }
    }

    static async draftBudget(component: Vue, budget: Budget) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/budgets/draft", budget, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.budget = JsonTool.jsonConvert.deserializeObject(response.data, Budget)
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al guardar los presupuestos.")
        } finally {
            // @ts-ignore
            component.loading = true
        }
    }

    static async patchBudget(component: Vue, budget: Budget) {
        // @ts-ignore
        component.loading = true
        try {
            const response = component.axios.patch(ConstantTool.BASE_URL + "/api/budgets/" + budget.id, budget, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            // getModule(SnackbarModule).makeToast("Se ha editado el presupuesto.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al editar el presupuesto.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async deleteBudget(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.delete(ConstantTool.BASE_URL + "/api/budgets/" + id, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            getModule(SnackbarModule).makeToast("El presupuesto se ha eliminado con exito.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al eliminar el presupuesto.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async postBudget(component: Vue, budget: Budget, potentialClientId: number, send: boolean = false) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/potential-clients/" + potentialClientId + "/budgets",
                budget, {
                    headers: {Authorization: getModule(SessionModule).session.token}
                })
            const budgetResponse = JsonTool.jsonConvert.serializeObject(response.data, Budget)

            if (send) { await this.sendBudget(component, budgetResponse.id!)}

            getModule(SnackbarModule).makeToast("Generado un presupuesto.");

        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al crear el presupuesto.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }



    static async sendBudget(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/budgets/" + id + "/send", null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            getModule(SnackbarModule).makeToast("presupuesto enviado con exito!")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al enviar el presupuesto")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

}