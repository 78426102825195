var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h4',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.budgets))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"depressed":"","small":"","tile":"","color":"pb-barberia","dark":""},on:{"click":function($event){return _vm.$router.push('/budget')}}},[_vm._v(_vm._s(_vm.lang.createBudget))]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"pb-peluqueria","tile":"","small":"","depressed":"","disabled":_vm.sendButtonDisabled},on:{"click":_vm.sendBudgets}},[_vm._v(_vm._s(_vm.lang.send))])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.budgets,"headers":_vm.headers,"hide-default-footer":"","height":"380px","page":_vm.page,"fixed-header":"","mobile-breakpoint":0,"no-data-text":_vm.loading ? _vm.lang.searching : _vm.lang.noResults,"disable-sort":"","options":_vm.options,"single-select":true,"items-per-page":_vm.itemsPerPage,"show-select":!_vm.isCommercial,"server-items-length":_vm.totalItems},on:{"update:items":function($event){_vm.budgets=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item,"ripple":false,"disabled":_vm.isSelectable(item)},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}},{key:"header.event",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium",attrs:{"clearable":"","hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.search,"append-icon":"mdi-magnify"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","outlined":"","width":"100%","min-height":"40px","color":"#9e9e9e"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.lang.filterByDate)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_vm._v("mdi-calendar-clock-outline")])],1)]}}],null,true)},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-date-picker',{attrs:{"year-icon":"mdi-calendar-blank","prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","multiple":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-card-actions',[_c('v-sheet',{staticClass:"mx-auto px-8 py-2 pointer",attrs:{"depressed":"","color":"pb-grey"},on:{"click":function($event){_vm.dates = []}}},[_vm._v(_vm._s(_vm.lang.clearFilters))]),_c('v-sheet',{staticClass:"mx-auto px-8 py-2 pointer",attrs:{"depressed":"","color":"pb-success"},on:{"click":_vm.watchOptions}},[_vm._v(_vm._s(_vm.lang.search))])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.programmedAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium",attrs:{"clearable":"","hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.search,"append-icon":"mdi-magnify"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium",attrs:{"clearable":"","hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.search,"append-icon":"mdi-magnify"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.bruh",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium",attrs:{"clearable":"","hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.search,"append-icon":"mdi-magnify"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.potentialClient",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByClient,"clearable":"","items":_vm.potentialClients,"outlined":"","item-text":"name","item-value":"id","autocomplete":"null"},on:{"change":_vm.watchOptions,"click:clear":_vm.clearCommercial},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{staticClass:"my-2"},[_c('v-list-item-title',[_c('h3',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.lastname))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.commercialName))])],1)]}}],null,true),model:{value:(_vm.potentialClientId),callback:function ($$v) {_vm.potentialClientId=$$v},expression:"potentialClientId"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByStatus,"items":_vm.orderTypes,"outlined":"","autocomplete":"null","item-text":"name","item-value":"value","clearable":""},on:{"change":_vm.watchOptions},model:{value:(_vm.orderType),callback:function ($$v) {_vm.orderType=$$v},expression:"orderType"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.totalAmount",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"width":"100%","height":"40px"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.potentialClient",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info","label":"","outlined":""}},[_c('span',{staticClass:"grey--text text--darken-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.potentialClient.name)+" "+_vm._s(item.potentialClient.lastname)+" ")])])]}},{key:"header.edit",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-1",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"width":"100%","height":"40px"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('OrderStatusComponent',{attrs:{"orderType":item.budgetType}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"info","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.createdBy.commercialName)+" ")])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.getTotalPrice(item.budgetDetails).toFixed(2))+"€ ")])]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.connectorId)+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","transition":"slide-x-transition","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"width":"250","outlined":"","flat":"","color":"#5D6162","dark":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.lang.options))]),_c('div',{staticClass:"px-3"},[_c('v-divider')],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/budget-details/' + item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.lang.seeBudget))])],1)],1),(!_vm.isDisableEdit(item))?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/budget/' + item.id + '/edit')}}},[_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.lang.editBudget))])],1)],1):_vm._e(),(!_vm.isDisableEdit(item))?_c('v-list-item',{on:{"click":function($event){return _vm.deleteBudget(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.lang.deleteBudget))])],1)],1):_vm._e()],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}}),_c('v-card-actions',[_c('h5',{staticClass:"ml-auto font-weight-medium grey--text text--darken-1"},[_vm._v(_vm._s(_vm.lang.budgetTableHint))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }