













































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import OrderService from "@/services/OrderService";
import Order, {OrderSelect, OrderType} from "@/models/Order";
import Options from "@/models/vue/Options";
import Client from "@/models/Client";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import User from "@/models/User";
import UserService from "@/services/UserService";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import LangModule from "@/store/LangModule";
import OrderDetail from "@/models/OrderDetail";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue"
import BudgetService from "@/services/BudgetService";
import Budget, {BudgetType} from "@/models/Budget";
import PotentialClientService from "@/services/PotentialClientService";
import BudgetDetail from "@/models/BudgetDetail";

@Component({components: { OrderStatusComponent }})
export default class BudgetView extends Vue {
	lang: any = getModule(LangModule).lang
	loading: boolean = false
	sendButtonDisabled = true
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	totalItems: number = 0
	search: string = ""
	budgets: Budget[] = []
	selected: Order[] = []

	headers = [
		{ align: "center", width: "auto", text: this.lang.buyDateAtHeader, value: "createdAt" },
		{ align: "center", width: "auto", text: this.lang.createdBy, value: "createdBy" },
		{ align: 'center', width: 'auto', text: this.lang.potentialClient, value: 'potentialClient' },
		{ align: "center", width: "110px", text: this.lang.status, value: "status" },
		{ align: 'center', width: '100px', text: this.lang.total, value: 'totalAmount' },
		{ align: "center", width: "100px", text: this.lang.edit, value: "edit" },
	]

	options: Options = new Options()
	picker: string = ""
	dates: string[] = [this.picker]
	potentialClients: Client[] = []
	client: Client = new Client()
	commercial: User = new User()
	commercials: User[] = []
	clientsPage: number = 0
	// @ts-ignore
	orderType: OrderType | null = null

	orderTypes: OrderSelect[] = [
		{ name: this.lang.draft, value: OrderType.DRAFT },
		{ name: this.lang.onHold, value: OrderType.WAITING },
		{ name: this.lang.sent, value: OrderType.SEND },
	]

	//Filter models
	commercialId: number | null = null
	potentialClientId: number | null = null

	orderTypeFinish: OrderType = OrderType.FINISH
	orderTypeSend: OrderType = OrderType.SEND
	orderTypeDraft: OrderType = OrderType.DRAFT
	sessionModule: SessionModule = getModule(SessionModule)
	isCommercial = this.sessionModule.session.authorities.map(e => e.title == "commercial")[0]
	all: boolean = false
	filterColumn: number = 4
	orderColumn: number = 8
	expand: boolean = true

	expandFilters() {
		this.expand = !this.expand
		if (!this.expand) {
			this.filterColumn = 2
			this.orderColumn = 10
		}
		if (this.expand) {
			this.filterColumn = 4
			this.orderColumn = 8
		}
	}

	getTotalPrice(budgetDetails: BudgetDetail[]) {
		let totalPrice: number = 0
		budgetDetails.forEach(budgetDetail => { totalPrice += (Number(budgetDetail.product!.commercialRate) * Number(budgetDetail.quantity)) })
		return totalPrice
	}

	isSelectable(item: Budget){
		return item.budgetType == BudgetType.SEND
	}

	async sendBudgets() {
		for (let item of this.selected) {
			if (item.id != null) {
				await BudgetService.sendBudget(this, item.id)
				await this.refresh()
			}
		}
	}

	@Watch("selected")
	sendBtnHidden(){
		console.log(this.selected)
		this.sendButtonDisabled = this.selected.length == 0
	}

	async sendOrdersButtonClick(){
		for (let item of this.selected) {
			if(item.id != null) {
				await OrderService.sendOrder(this, item.id)
				await this.refresh()
			}
		}
	}

	clearCommercial() {
		this.commercial = new User()
		this.watchOptions()
	}

	isDisableEdit(item: Budget): boolean {
		return (item.budgetType === BudgetType.FINISH) || (item.budgetType === BudgetType.SEND)
	}

	isDisableView(item: Order): boolean {
		return (item.orderType === this.orderTypeDraft)
	}

	async loadClients([intersectionEntry]: IntersectionObserverEntry[]) {
		if (intersectionEntry.isIntersecting) {
			const oldClients: Client[] = [...this.potentialClients]
			this.clientsPage++
			await this.getClients()
			const newClients: Client[] = [...this.potentialClients]
			this.potentialClients = [...oldClients, ...newClients]
		}
	}

	async getClients() {
		await PotentialClientService.getPotentialClients(this, this.potentialClients, this.clientsPage, 10, "")
		if (this.$route.query.clientId) {
			const id = this.$route.query.clientId
			const client = this.potentialClients.find(e => e.id === Number(id))
			console.log(client!.email)
			this.client = client!
			await this.watchOptions()
		}
	}

	created() {
		if (this.$route.query.all === "1") {
			this.all = true
		}
		this.getClients()
		this.getCommercials()
	}

	async getCommercials() {
		await UserService.getMyCommercials(this, this.commercials, 0, 100, false, true)
		if (this.$route.query.commercial) {
			const id = this.$route.query.commercial
			const commercial = this.commercials.find(e => e.id === Number(id))
			console.log(commercial)
			this.commercial = commercial!
			await this.watchOptions()
		}
	}

	@Watch("options")
	async watchOptions() {
		console.log("WatchOptions")
		if (this.isCommercial || this.all) {
			await BudgetService.getMyBudgets(
				 this, this.budgets, this.page - 1, this.itemsPerPage, this.sessionModule.session!.user!.id!,
				 this.search, null, null, this.dates[0], this.dates[1]
			)
		} else {

			console.log("XDDD")

			await BudgetService.getBudgets(
				 this, this.budgets, this.page - 1, this.itemsPerPage, this.search,
				 null, null, this.dates[0], this.dates[1], null
			)
		}
	}

	@Watch("all")
	watchAll() {
		this.watchOptions()
	}

	async refresh() {
		await this.watchOptions()
		this.selected = []
	}

	rowClick(order: Order) {
		this.$router.push('/order-details/' + order.id)
	}

	deleteBudget(id: number) {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning,
			 this.lang.deleteOrderQuestion,
			 () => BudgetService.deleteBudget(this, id)
		))
	}

}
